<template>
  <div class="home-wrapper page-component__scroll" >
    <el-container>

      <headerCom></headerCom>

      <el-main>
        <!--banner-->
        <div class="block banner" style="height:800px;margin: 0;overflow: hidden;position: relative;">
          <div class="banner_titile" style="position: absolute;bottom: 200px;left: 0;width: 630px;height: 250px;background: rgba(69,138,190,.5);">
            <h3 style="font-size: 48px;width:80%;margin: auto;line-height: 70px;margin-top: 50px;text-align: center;color: #fff;font-weight: normal;letter-spacing: 5px;overflow: hidden;">
              <span style="display: block;float: left;">专业的服务水准</span><span  style="display: block;float: right;">良好的执业操守</span>
            </h3>
          </div>
        </div>
        <!--业务领域-->
        <div class="block business" style="height: 1365px;background: none;">
          <div class="bg-wrapper" style="background:#051D37;height: 1470px;">
            <div class="content" style="width: 1200px;margin: auto;height:100%;padding-top: 70px;">
              <h2 style="text-align: left;width: 1200px;transform: translate(0, 0);position: inherit;margin:0;font-weight: bold;">业务领域</h2>
              <p style="color:#fff;margin-top: 10px;">茂恒会计师事务所，会计审计事务行业的领先品牌，专业和优质的服务是我们的成功密码！</p>
              <div class="body">
                <ul>
                  <li @click="goToPage('jzfw')">
                    <img src="../assets/images/jianzheng.jpg" />
                    <p class="b_title">鉴证服务</p>
                    <b class="icon-bg"></b>
                    <i class="el-icon-right"></i>
                    <div class="bottom">
                      <span>鉴证业务是指注册会计师对鉴证对象信息提出结论，以增强除责任方之外的预期使用者对鉴证对象信息信任程度的业务。</span>
                    </div>
                  </li>
                  <li class="second" @click="goToPage('jjzrsj')" style="background:#40658E;">
                    <p class="b_title" style="left:calc(50% - 75px);width: 152px;">经济责任审计</p>
                    <b class="icon-bg"></b>
                    <i class="el-icon-right"></i>
                    <div class="bottom">
                      <span>经济责任审计，是指为企业高管在本单位任职期间，对其管辖范围内贯彻执行企业的经济方针政策、经济政策落实结果、经济廉洁等有关经济活动应当履行的职责进行专项审计。</span>
                    </div>
                  </li>
                  <li class="third" @click="goToPage('glzx')" style="background:#122D4D;">
                    <p class="b_title">管理咨询</p>
                    <b class="icon-bg"></b>
                    <i class="el-icon-right"></i>
                    <div class="bottom">
                      <span>管理咨询以深入企业为基础，并且和企业管理人员密切结合，应用科学的方法，找出企业存在的主要问题，进行定量和确有论据的定性分析。</span>
                    </div>
                  </li>
                  <li @click="goToPage('gcjssj')">
                    <img src="../assets/images/zixun.jpg">
                    <p class="b_title" style="left:calc(50% - 75px);width: 152px;">工程结算审计</p>
                    <b class="icon-bg"></b>
                    <i class="el-icon-right"></i>
                    <div class="bottom">
                      <span>工程结算审计是对施工单位在工程竣工或年度终了时，与建设单位所办理的工程价款结算的审计。</span>
                    </div>
                  </li>
                  <li @click="goToPage('yzqyzb')">
                    <img src="../assets/images/caishui.jpg" style="margin-top: -40px">
                    <p class="b_title" style="left:calc(50% - 75px);width: 152px;">验证企业资本</p>
                    <b class="icon-bg"></b>
                    <i class="el-icon-right"></i>
                    <div class="bottom">
                      <span>资本验证是指注册会计师依法接受委托，对被审验单位注册资本的实收情况或注册资本及实收资本的变更情况进行审验，并出具验资报告。</span>
                    </div>
                  </li>
                  <li class="second" @click="goToPage('kjfw')" style="background:#40658E;">
                    <p class="b_title">会计服务</p>
                    <b class="icon-bg"></b>
                    <i class="el-icon-right"></i>
                    <div class="bottom">
                      <span>会计服务为各类企业提供全方位的、专业化的财税服务，解决企业财务、税务、工商、年检等各类“疑难杂症”。</span>
                    </div>
                  </li>
                  <li class="second" @click="goToPage('cwjl')" style="background:#122D4D;">
                    <p class="b_title">财务监理</p>
                    <b class="icon-bg"></b>
                    <i class="el-icon-right"></i>
                    <div class="bottom">
                      <span>财务监理工作计划既要遵循审计准则的规定要求，也要符合单项财力投资项目的具体情况，更要符合国家有关财力投资项目管理要求。</span>
                    </div>
                  </li>
                  <li @click="goToPage('jzdc')">
                    <img src="../assets/images/diaocha.jpg" style="margin-top: -40px">
                    <p class="b_title">尽职调查</p>
                    <b class="icon-bg"></b>
                    <i class="el-icon-right"></i>
                    <div class="bottom">
                      <span>尽职调查指在收购过程中收购者对目标公司的资产和负债情况、经营和财务情况、法律关系以及目标企业所面临的机会与潜在的风险。</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--案例展示-->
        <!-- <div class="block block_indent contact" style="background: #fff;height: auto;margin-bottom: 100px;">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>案例展示</span></p>
          </h2>
          <p style="color:#333;position: absolute;top: -50px;left:0;">茂恒律师事务所诚挚期待与您的合作，平等合作、优势互补、共同发展！</p>
          <ul>
            <li>
              <div class="img-wrapper">
                <img src="../assets/images/anli-1.jpg" />
              </div>
              <div class="content">
                <h4>会计师事务案例1</h4>
                <p>会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容...</p>
                <a style="position: absolute;bottom: 20px;left: calc(50% - 30px);" @click="openMore">展开<i class="el-icon-arrow-down"></i></a>
              </div>
            </li>
            <li>
              <div class="img-wrapper">
                <img src="../assets/images/anli-2.jpg" />
              </div>
              <div class="content">
                <h4>会计师事务案例2</h4>
                <p>会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容...</p>
                <a style="position: absolute;bottom: 20px;left: calc(50% - 30px);" @click="openMore">展开<i class="el-icon-arrow-down"></i></a>
              </div>
            </li>
            <li>
              <div class="img-wrapper">
                <img src="../assets/images/anli-3.jpg" />
              </div>
              <div class="content">
                <h4>会计师事务案例3</h4>
                <p>会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容会计师事务案例内容...</p>
                <a style="position: absolute;bottom: 20px;left: calc(50% - 30px);" @click="openMore">展开<i class="el-icon-arrow-down"></i></a>
              </div>
            </li>
          </ul>
        </div> -->
        <!--合作共赢-->
        <div class="block block_indent sub_banner" style="overflow: hidden;position: relative;background: #efefef;height: 300px;width: 100%;">
          <div class="banner-bg">
            <p style="color:#fff;font-size: 30px;float:left;margin-top: 130px;margin-left:calc(50% - 600px);font-weight: bold;letter-spacing: 2px;"><strong style="background: rgba(49,126,198,.6);padding: 10px 20px;margin: 0;">WIN-WIN </strong> <strong style="color:#317EC6;background: rgba(255,255,255,.6);padding: 10px 20px;margin: 0;">COOPERATION</strong></p>
          </div>
          <img src="../assets/images/sub_banner.jpg" style="display: block;width: 100%;object-fit: cover;height: 100%;">
          <div class="banner-title" style="position: absolute;z-index: 100;top: calc(50% - 50px);color:#fff;right:calc(50% - 600px);letter-spacing: 3px;text-align: center;font-size: 42px;background: #317EC6;width: 400px;height: 100px;line-height: 100px;"><span style="margin-right:20px;">合作</span><span style="margin-left:20px;">共赢</span></div>
        </div>
        
        <div class="block block_indent idea">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>专业化服务</span></p>
          </h2>
          <p style="color:#333;position: absolute;top: -50px;left:0;">茂恒律师事务所为您提供专业化的服务!</p>

          <div class="body" style="overflow: hidden;height: 100%;">
            <div class="pic" style="width: 50%;height: 100%;float: left;">
              <!-- <img src="../assets/images/fuwu.jpg" style="object-fit: contain;">  -->
            </div>
            <div class="content" style="background: #333;width: 50%;height: 100%;float: left;position: relative;">
              <div class="stepps-content" style="color:#fff;width: 90%;margin: 35px auto 0;">
                <!-- <h4 style="margin: 10px 0;">服务保障</h4> -->
                <p style="margin: 50px 23px;color:#aaa">我们有高效专业的财税团队，有注册会计师、注册税务师、管理咨询师等多名优秀专业人才，以丰富的实战经验和超强的专业能力热忱地为客户服务。</p>
                <!-- <h4 style="margin: 10px 0;">服务保障</h4> -->
                <p style="margin: 70px 23px;color:#aaa">我们可以帮您清理各种 复杂的疑账、杂账，帮你排忧解难。茂恒人始终坚持客观、公正、独立的原则，凭借在审计、验资等方面上丰厚经验，以专业高效严谨的精神为您提供管家式的服务。</p>
                <!-- <h4 style="margin:  10px 0;">服务保障</h4> -->
                <p style="margin: 70px 23px;color:#aaa">始终坚持“只有客户获得成功，我们才能获得成功”的理念，用专业知识和 优质的服务取得客户的信任和支持。</p>
              </div>
              <el-steps align-center direction="vertical" :active="3" style="width: 100%;position: absolute;top: 20px;left: 20px;">
                <el-step title="服务保障" @click="mouseenter" icon="el-icon-s-claim"></el-step>
                <el-step title="服务原则" icon="el-icon-collection"></el-step>
                <el-step title="服务理念" icon="el-icon-s-operation"></el-step>
              </el-steps>
            </div>
          </div>
        </div>
       
        <div class="block block_indent cooperate" style="background:#efefef;height: 400px;margin-bottom: 100px;">
          <h2 style="transform:translate(0, 0);left:0;color:#333;font-weight: bold;top: -30px">
            <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>联系我们</span></p></h2>
            <p style="color:#333;position: absolute;top: -50px;left:0;">茂恒律师事务所欢迎您的到来！</p>
            <div class="map-wrapper" style="width: 100%;height: 100%;overflow: hidden;"> 
              <div id="addrailmap" style="width:750px;height:400px;float: left"></div>
              <!-- <input type="radio" name='func' value='polygon'> -->

              <div class="contact" style="width: 450px;float: left;">
                <p style="font-size: 15px;margin: 0 0 0 5px;line-height: 24px;color:#177790;border-left: 3px solid #177790;padding:30px 20px 30px 20px;background: #E0EBED; ">事务所资质：上海股交中心专业会员单位、非公众公司审计鉴证资质、上海市政府采购招标中心供应商资质、上海市高新技术企业认定审计资质等。 茂恒在企业内部倡导诚信、尊重、学习、互助的企业文化，大家共同奋进，打造一个平等、开放的学习型组织。在茂恒的平台上，希望能为每一位茂恒人提供合适的机会和更广阔的成长空间！</p>
                <p style="padding: 30px;line-height: 30px;text-align: right;font-size: 15px;border-right: 3px solid #999;">
                电话：<strong style="font-size: 21px;color:#177790">+86-21-6212-9188</strong><br />
                传真：<strong style="font-size: 21px;color:#177790">+86-21-6212-6080</strong><br />
                Email: <strong style="font-size: 21px;color:#177790">evan@maohengcpa.com</strong></p>
                <p style="text-align: right;padding:0 30px 30px 0;font-size: 15px;border-right: 3px solid #999;">地址：上海市长宁区延安西路728号华敏翰尊大厦10楼B座</p>
              </div>
            </div>
        </div>
      </el-main>
      <el-footer>
        <footerCom></footerCom>
      </el-footer>

    </el-container>
  </div>
</template>

<script>
import headerCom from './children/header.vue' 
import footerCom from './children/footer.vue' 


export default {
  name: 'Index',
  props: [],
  components: { headerCom,footerCom },
  data() {
    return {
      clientWidth: 0,
      isMobile: true,
      activeIndex: '0',
      position: [121.43377,31.215414],
    }
  },
  created() {


  },
  mounted(){
    this.$nextTick(()=>{
      this.clientWidth = document.body.clientWidth;
      this.isMobile = this.clientWidth<900?true:false;
      this.activeIndex = this.clientWidth<900?'0':'1';

      window.addEventListener('resize', () => {
        this.clientWidth = document.body.clientWidth;
        this.isMobile = this.clientWidth<900?true:false;
        this.activeIndex = this.clientWidth<900?'0':'1';
      });


      this.initaddrailmap();

    });
  },
  methods: {
    openMore(){},
    mouseenter(){
      console.log("enter");
    },
    stepHover(){
      console.log("hover");
    },
    goToPage(title){
      this.$router.push({'path':'/business',query:{title:title}})
    },
    initaddrailmap(){
         this.$nextTick(function () {
            this.map = new window.AMap.Map("addrailmap", {
              resizeEnable: true,
              center: this.position,
              zoom: 16
            });

        //     var markerContent = '' +
        // '<div class="custom-content-marker">' +
        // '   <img src="//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png">' +
        // '   <div class="close-btn" onclick="clearMarker()">X</div>' +
        // '</div>';

        

          var marker = new window.AMap.Marker({
              position: this.position,
              // 将 html 传给 content
              // content: markerContent,
              // 以 icon 的 [center bottom] 为原点
              offset: new window.AMap.Pixel(-13, -30)
          });

          // 将 markers 添加到地图
          this.map.add(marker);



         });
    }
  }
}
</script>

<style lang="scss">
.el-dropdown-menu {
  top: 40px !important;
  .el-dropdown-menu__item {
    width: 120px;
    text-align: center;
  }
}
// .topMenu {
//   width: 100% !important;
//   left: 0 !important;
//   background: (0,0,0,.4);
//   ul {
//     overflow: hidden;
//     width: 100%;
//     height: 100px;
//     text-align: right;
//     margin-top: 2px !important;
//     padding: 0 !important;
//     background: #E0EBED;
//     h3 {
//       float:left;width: calc(35% - 30px);height: 100%;text-align: right;
//       padding-right: 30px;background: #0567B8;
//       line-height: 100px;
//       font-size: 24px;
//       font-weight: normal;
//       color: #fff;
//       margin-right: 80px;
//       text-shadow: 2px 4px 3px rgba(255, 255, 255, 0.9);
//     mix-blend-mode: multiply;
//     }
//     li {
//       float: left !important;
//       margin: 0 25px;
//       background: none !important;
//       line-height: 100px !important;
//       color: #333 !important;
//       font-size: 15px !important;
//       list-style: disc inside;
//       font-weight: bolder;
//       // padding-top: 30px !important;
//       text-align: center;
//       &::before {
//         display: block;
//         font-size: 22px;
//         text-align: center;
//         border: 5px solid #ccc;
//         border-radius: 50%;
//         width: 50px;
//         height: 50px;
//       }
//     }
//   }
// }
.home-wrapper{
  .el-container{
    width: 100%;overflow: hidden;
    .el-header{
      height: auto !important;
      .el-menu{
        height: 80px;
        
        .el-menu-item,.el-submenu,.el-submenu .el-submenu__title {
          font-size: 16px;
          width: 100px;
          text-align: center;
          padding: 0 20px;
          height: 80px;
          line-height: 80px;
        }
      }
      .downMenu {
        position: absolute;
        top: 0px;
        right: 0px;
        span {
          display: block;
          width: 30px;
          height: 0;
          margin: 8px 0;
          border: 1px solid #999;
        }
      }
    }
    .el-main{
      padding: 0;
      width: 100%;
      .block{
        width: 100%;
        margin: 0 0 200px 0;
        height: 600px;
        
        position: relative;
        h2{
          text-align: center;
          color:#fff;
          font-size: 38px;
          font-weight: normal;
          position: absolute;
          top: 0;
          left: 50%;
          margin-top: 30px;
          transform: translate(-50%, 0);
        }
        .banner-bg {
          display: block;width: 100%;height: 100%;background: rgba(98,169,215,.5);position: absolute;top:0;left:0;z-index: 100;overflow: hidden;
        }
        &.banner {
          background-image: url("../assets/images/banner_1.jpg");
          background-size: 100% auto;
          background-attachment:fixed;
          background-repeat:no-repeat;
          background-size: cover;
          background-position: center;
        }
        &.business {
          .content .body {
            width: 100%;
            margin-top: 60px;
            ul {
              overflow: hidden;
              padding: 0;
              margin: 0;
              li {
                width: 50%;float: left;
                height: 288px;
                overflow: hidden;
                position: relative;
                &.second{
                  transition: all 0.3s;
                  &:hover {
                    background: #275994 !important;
                  }
                }
                &.third {
                  transition: all 0.3s;
                  &:hover {
                    background: #000D3A !important;
                  }
                }
                p {
                  position: absolute;
                  top: calc(50% - 15px);
                  left: calc(50% - 50px);
                  font-size: 22px;
                  color: #fff;
                  letter-spacing: 3px;
                  z-index: 100;
                  cursor: pointer;
                  transition: all 0.3s;
                }
                .bottom {
                  width: calc(100% - 80px);
                  height: 100%;
                  position: absolute;
                  left: 0;bottom: 0;
                  top: 100%;
                  z-index: 1;
                  transition: all 0.2s;
                  padding: 0 40px;
                  background: rgba(0,0,0,.5);
                  span {
                    padding-top: 130px;
                    display: block;
                    color: #fff;
                    font-size: 16px;
                    line-height: 28px;
                    text-decoration: underline;
                    text-underline-offset: 5px;
                    cursor: pointer;
                  }
                }
                img {
                  transition: all 0.3s;
                }
                i {
                  position: absolute;
                  cursor: pointer;
                  font-size: 28px;
                  color: #177790;
                  z-index: -1;
                  right: 18px;
                  bottom: 18px;
                }

                .icon-bg {
                  position: absolute;
                  z-index: 100;
                  width: 150px;
                  height: 150px;
                  background: #fff;
                  right: -120px;
                  bottom: -110px;
                  transform: translate3d(7%,16%,0) rotate(47deg);
                }

                &:hover {
                  .bottom {
                    transition: all 0.2s;
                    top: 0;
                  }
                  p{
                    transition: all 0.2s;
                    top: 90px;
                  }
                  img {
                    transition: all 0.3s;
                    transform: scale(1.3);
                  }
                  .icon-bg {
                    transition: all 0.3s;
                    transition-delay:0.1s;
                    right: -60px;
                    bottom: -55px;
                  }
                  i {
                    z-index: 100;
                    // transition: all 0.3s;
                    transition-delay:0.2s;
                  }
                }
                
              }
            }
          }
        }
        &.idea {
          .pic {
            background: url('../assets/images/fuwu.jpg');
            background-size: 100% auto;
            background-position: 0 -70px;
          }
          .el-step {
            cursor: pointer;
            .el-step__icon{
              background: none;
              .el-step__icon-inner {
                color: #fff;
              }
            }
            &.is-vertical .el-step__line{
              top: 30px;
              bottom: 5px;
              color:#ccc;
            }
            .el-step__title.is-process,.el-step__title.is-finish,.el-step__title.is-wait {
              color: #fff;
              font-weight: normal;
              &:hover {
                color: #fff;
              }
            }
          }
        }
        &.contact {
          ul {
            padding: 0;
            margin: 0;
            li {
              background: #E0EBED;
              width: 100%;
              height: 250px;
              margin: 20px 0;
              overflow: hidden;
              .img-wrapper {
                overflow: hidden;
                height: 100%;
                width: 400px;
                float: left;
                img {
                  display: block;
                  height: auto;
                  width: 100%;
                }
              }
              .content {
                width: 800px;
                float: left;
                padding: 10px 30px;
                box-sizing: border-box;
                position: relative;height: 100%;
                h4 {
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 1.4;
                  margin-bottom: 22px;
                  color: #585858;
                }
                p {
                  line-height: 1.8;
                  margin-bottom: 22px;
                }
                a {font-size: 15px;color:#177790;cursor: pointer;}
              }
            }
          }
        }
        &.block_indent{
          width: 1200px;
          height:400px;
          margin: 0 auto 200px;
          background: #ccc;
          h2 {
            margin-top: -80px;
            color: #0052CA;
          }
        }
      }
    }
    .el-footer {
      width:100%;
      padding: 0;
      height: 300px !important;
      background: #181818;
    }
  }
}

@media screen and (max-width:991px) {
  .home-wrapper{
    .el-container{
      .el-main{
        .block.banner{
          height: 500px!important;
          .banner_titile {
            bottom:130px!important;
            height: 120px!important;
            width: 400px !important;
          }
          h3 {
            
            line-height: 50px!important;
            margin: 10px auto auto!important;
            font-size: 32px!important;
          }
        }
        .block.block_indent {
          width: 100%;  
        }
        .block {
          h2 {
            padding: 0 30px;
            font-size: 26px;
          }
          p {
            padding: 0 30px;
          }
          .content {
            width: 100% !important;
          }
        }
        .banner-title {
          right: 0!important;
        }
        .block_indent.idea {
          .pic {
            display: none!important;
          }
        }
      } 
      .el-footer {
        height: 560px!important;
        .footerCom {
            .footer-wrapper {
              width:100%!important;
              box-sizing: border-box !important;
              padding: 0 30px !important;
              .left-content {
                width: 500px!important;
                margin: 30px auto!important;
                float:none !important;
                overflow: hidden;
              }
              .right-content {
                width: 500px!important;
                margin: 100px auto 50px!important;
                float:none !important;
              }
            }
        }
      }
    } 
  } 
}
</style>
