<template>
    <el-header :style="{'padding':isMobile?'0 30px':'0 70px'}">
        <el-row :gutter="20" style="overflow: hidden;">
          <el-col :span="6" class="logo" v-if="!isMobile" style="margin-top: 8px;overflow: hidden;width:380px;float:left;">
            <img src="../../assets/images/logo.png" @click="goToHome" style="cursor: pointer;width:auto;height: 60px;display: inline-block;float: left;margin-right: 10px;">
            <span @click="goToHome" style="display: inline-block;cursor: pointer;float: left;font-size: 20px;;color: #787878;vertical-align: bottom;font-weight: bold;letter-spacing: 6px;margin-top: 10px;">上海茂恒会计师事务所</span>
            <span @click="goToHome" style="font-size:12px;float:left;cursor: pointer;">Shanghai Maoheng Certified Public Accountants</span>
          </el-col>
          <el-col :span="18" v-if="isMobile" class="menu">
            <img src="../../assets/images/logo.png" style="width:auto;height: 60px;display: inline-block;">
            <a style="display: inline-block;line-height: 60px;color: #787878;vertical-align: bottom;font-weight: bold;">上海茂恒会计师事务所</a>
          </el-col>
          <el-col :span="18" v-if="!isMobile" class="menu" style="overflow: hidden;width:800px;float:right;">
            <el-menu
              :default-active="activeIndex"
              
              class="el-menu-demo"
              style="width: 770px;float:right;"
              mode="horizontal"
              @select="handleSelect">
              <el-menu-item index="1">首页</el-menu-item>
              <el-submenu index="2" popper-class="topMenu">
                <template slot="title">审计验资</template>
                <h3>审计验资</h3>
                <el-menu-item index="2-1">报表审计</el-menu-item>
                <el-menu-item index="2-2">专项审计</el-menu-item>
                <el-menu-item index="2-3">挂牌上市</el-menu-item>
                <el-menu-item index="2-4">专业验资</el-menu-item>
              </el-submenu>
              <el-submenu index="3" popper-class="topMenu">
                <template slot="title">公司代理</template>
                <h3>公司代理</h3>
                <el-menu-item index="3-1">公司注册</el-menu-item>
                <el-menu-item index="3-2">公司变更</el-menu-item>
                <el-menu-item index="3-3">公司注销</el-menu-item>
              </el-submenu>
              <el-submenu index="4" popper-class="topMenu">
                <template slot="title">财税咨询</template>
                <h3>财税咨询</h3>
                <el-menu-item index="4-1">税务顾问</el-menu-item>
                <el-menu-item index="4-2">税收策划</el-menu-item>
                <el-menu-item index="4-3">税务代理</el-menu-item>
                <el-menu-item index="4-4">稽查应对</el-menu-item>
                <el-menu-item index="4-5">财税培训</el-menu-item>
              </el-submenu>
              <el-submenu index="5" popper-class="topMenu">
                <template slot="title">代理记账</template>
                <h3>代理记账</h3>
                <el-menu-item index="5-1">服务范围</el-menu-item>
                <el-menu-item index="5-2">独特优势</el-menu-item>
                <el-menu-item index="5-3">服务流程</el-menu-item>
              </el-submenu>
              <el-menu-item index="6">关于我们</el-menu-item>
            </el-menu>
          </el-col>
          <el-col :span="6" v-if="isMobile" style="text-align: right;">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <!-- 菜单<i class="el-icon-arrow-down el-icon--right"></i> -->
                <div class="downMenu">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>首页</el-dropdown-item>
                <el-dropdown-item>关于我们</el-dropdown-item>
                <el-dropdown-item>新闻中心</el-dropdown-item>
                <el-dropdown-item>业务合作</el-dropdown-item>
                <el-dropdown-item>联系我们</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>

    </el-header>
</template>
  
  <script>
    export default {
      data() {
        return {
            activeIndex: '2',
            isMobile: false,
        }
      },
      created(){
  
      },
      mounted() {
        // 实现swiper双向控制
        this.$nextTick(() => {
            // this.clientWidth = document.body.clientWidth;
            // this.isMobile = this.clientWidth<900?true:false;
            // this.activeIndex = this.clientWidth<900?'0':'1';

            // window.addEventListener('resize', () => {
            //     this.clientWidth = document.body.clientWidth;
            //     this.isMobile = this.clientWidth<900?true:false;
            //     this.activeIndex = this.clientWidth<900?'0':'1';
            // });

            this.activeIndex = this.$route.query.s_title || this.$route.query.f_title || '1'
          
        })
      },
      methods: {
        goToHome(){
            this.$router.push({'path':'/',query:{}})
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            if(key==1){
                this.$router.push({'path':'/',query:{}})
            }else{
                this.$router.push({'path':'/subpage',query:{f_title:keyPath[0],s_title:keyPath[1]}})
            }
            
        }
      }
    }
  </script>


  <style lang="scss">
  .topMenu {
    width: 100% !important;
    left: 0 !important;
    background: (0,0,0,.4);
    ul {
        overflow: hidden;
        width: 100%;
        height: 100px;
        text-align: right;
        margin-top: 2px !important;
        padding: 0 !important;
        background: #E0EBED;
        h3 {
        float:left;width: calc(35% - 30px);height: 100%;text-align: right;
        padding-right: 30px;background: #0567B8;
        line-height: 100px;
        font-size: 24px;
        font-weight: normal;
        color: #fff;
        margin-right: 80px;
        text-shadow: 2px 4px 3px rgba(255, 255, 255, 0.9);
        mix-blend-mode: multiply;
        }
        li {
        float: left !important;
        margin: 0 25px;
        background: none !important;
        line-height: 100px !important;
        color: #333 !important;
        font-size: 15px !important;
        list-style: disc inside;
        font-weight: bolder;
        // padding-top: 30px !important;
        text-align: center;
        &::before {
            display: block;
            font-size: 22px;
            text-align: center;
            border: 5px solid #ccc;
            border-radius: 50%;
            width: 50px;
            height: 50px;
        }
        }
    }
    }
     .el-header{
      height: auto !important;
      .el-menu{
        height: 80px;
        
        .el-menu-item,.el-submenu,.el-submenu .el-submenu__title {
          font-size: 16px;
          width: 100px;
          text-align: center;
          padding: 0 20px;
          height: 80px;
          line-height: 80px;
        }
      }
      .downMenu {
        position: absolute;
        top: 0px;
        right: 0px;
        span {
          display: block;
          width: 30px;
          height: 0;
          margin: 8px 0;
          border: 1px solid #999;
        }
      }
    }
    @media screen and (max-width:991px) {
      .el-header {
        padding: 0 30px!important;
        .logo {margin-bottom: 10px;}
        .menu {
          display: none;
          width:100%!important;
          float: none!important;
          // display: none;
          ul {
            float: none!important;
            width: 100%!important;
          }
        }
      }
      
    }
  </style>