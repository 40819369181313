<template>
  <!-- <el-footer> -->
    <div class="footerCom">
      <div class="footer-wrapper" style="width: 1200px;margin: auto;overflow: hidden;">
        <div class="left-content" style="width: 650px;float: left;margin-top: 30px;">
          <ul>
            <i style="width: 20px;height: 2px;background: #177790;display: block;margin-bottom: 7px;"></i>
            <p>审计验资</p>
            <li @click="goToPage('2','2-1')">报表审计</li>
            <li @click="goToPage('2','2-2')">专项审计</li>
            <li @click="goToPage('2','2-3')">挂牌上市</li>
            <li @click="goToPage('2','2-4')">专业验资</li>
          </ul>
          
          <ul>
            <i style="width: 20px;height: 2px;background: #177790;display: block;margin-bottom: 7px;"></i>
            <p>公司代理</p>
            <li @click="goToPage('3','3-1')">公司注册</li>
            <li @click="goToPage('3','3-2')">公司变更</li>
            <li @click="goToPage('3','3-3')">公司注销</li>
          </ul>
          
          <ul>
            <i style="width: 20px;height: 2px;background: #177790;display: block;margin-bottom: 7px;"></i>
            <p>财税咨询</p>
            <li @click="goToPage('4','4-1')">税务顾问</li>
            <li @click="goToPage('4','4-2')">税收策划</li>
            <li @click="goToPage('4','4-3')">税务代理</li>
            <li @click="goToPage('4','4-4')">稽查应对</li>
            <li @click="goToPage('4','4-5')">财税培训</li>
          </ul>
          
          <ul>
            <i style="width: 20px;height: 2px;background: #177790;display: block;margin-bottom: 7px;"></i>
            <p>代理记账</p>
            <li @click="goToPage('5','5-1')">服务范围</li>
            <li @click="goToPage('5','5-2')">独特优势</li>
            <li @click="goToPage('5','5-3')">服务流程</li>
          </ul>
        </div>
        <div class="right-content" style="width:450px;float: right;margin: 50px 0;overflow: hidden;">
          <img src="../../assets/images/qrcode.jpg" style="width: 150px;height: auto;float: left;margin-right: 20px;">
          <div class="contact" style="float: left;width: 280px;">
            <p style="color: #b3b3b3;font-size: 16px;line-height: 28px;">欲了解更多会计审计事务信息，请扫描左侧二维码加入官方微信平台。</p>
            <div>
              <p style="color: #fff;font-size:16px;margin: 15px 0 20px 0;">业务合作热线：</p>
              <p style="color: #fff;font-size: 34px;letter-spacing: 2px;">186-1639-0535</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p style="color: #b3b3b3;text-align: center;line-height: 50px;">上海茂恒会计师事务所(普通合伙)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  备案号：沪ICP备12000802号-3</p>
      </div>
    </div>
  <!-- </el-footer> -->
</template>
  
  <script>
    export default {
      data() {
        return {
            isMobile: true
        }
      },
      created(){
  
      },
      mounted() {
        // 实现swiper双向控制
        this.$nextTick(() => {
          
        })
      },
      methods: {
        goToPage(f_title, s_title){
          this.$router.push({'path':'/subpage',query:{f_title:f_title,s_title:s_title}})
        },
      }
    }
  </script>


<style lang="scss">
  .footerCom {
    .left-content {
      p {
        color:#fff;
        font-size: 18px;
        letter-spacing: 2px;
        font-weight: 700;
        margin: 0 0 15px 0;
      }
      ul {
        width: 25%;
        float: left;
        margin: 0;padding: 0;
        li {
          color: #b3b3b3;
          font-size: 16px;
          margin: 5px 0;
          &:hover {
            color:#fff;
            cursor: pointer;
          }
        }
      }
    }
    .bottom {
      width:100%;
      height: 50px;
      background: #000;
      margin-top: 0;
    }
  }

  @media screen and (max-width:991px) {
    .el-footer {
        height: 560px!important;
        .footerCom {
            .footer-wrapper {
              width:100%!important;
              box-sizing: border-box !important;
              padding: 0 30px !important;
              background: #181818;
              .left-content {
                width: 500px!important;
                margin: 30px auto!important;
                float:none !important;
                overflow: hidden;
              }
              .right-content {
                width: 500px!important;
                margin: 100px auto 50px!important;
                float:none !important;
              }
            }
        }
      }
  }
</style>